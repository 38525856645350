import React from "react";

export default function ClockCircle() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 10.667V16.0003L19.3333 19.3337"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.33317 4.45075C11.2943 3.31629 13.5713 2.66699 15.9998 2.66699C23.3636 2.66699 29.3332 8.63653 29.3332 16.0003C29.3332 23.3641 23.3636 29.3337 15.9998 29.3337C8.63604 29.3337 2.6665 23.3641 2.6665 16.0003C2.6665 13.5718 3.3158 11.2948 4.45026 9.33366"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>

  );
}
