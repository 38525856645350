import React from "react";

export default function JobNotFound() {
  return (
    <svg
      width="454"
      height="454"
      viewBox="0 0 454 454"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_183_2679)">
        <path d="M454 0H0V454H454V0Z" fill="white" />
        <path
          d="M287.73 341.955H284.222C283.816 341.955 283.488 341.626 283.488 341.221V316.744C283.488 316.339 283.817 316.01 284.222 316.01H287.73C288.135 316.01 288.464 316.339 288.464 316.744V341.221C288.464 341.626 288.136 341.955 287.73 341.955ZM284.956 340.486H286.996V317.478H284.956V340.486Z"
          fill="#D1D1D1"
        />
        <path
          d="M287.73 341.955H284.222C283.816 341.955 283.488 341.626 283.488 341.221V316.744C283.488 316.339 283.817 316.01 284.222 316.01H287.73C288.135 316.01 288.464 316.339 288.464 316.744V341.221C288.464 341.626 288.136 341.955 287.73 341.955ZM284.956 340.486H286.996V317.478H284.956V340.486Z"
          fill="#D1D1D1"
        />
        <path
          d="M284.068 341.954H280.766C280.36 341.954 280.032 341.626 280.032 341.221V318.177C280.032 317.772 280.361 317.443 280.766 317.443H284.068C284.474 317.443 284.803 317.772 284.803 318.177V341.221C284.802 341.626 284.474 341.954 284.068 341.954ZM281.5 340.486H283.334V318.911H281.5V340.486Z"
          fill="#D1D1D1"
        />
        <path
          d="M284.16 341.954H280.857C280.452 341.954 280.123 341.626 280.123 341.221V318.177C280.123 317.772 280.452 317.443 280.857 317.443H284.16C284.565 317.443 284.894 317.772 284.894 318.177V341.221C284.894 341.626 284.565 341.954 284.16 341.954ZM281.591 340.486H283.426V318.911H281.591V340.486Z"
          fill="#D1D1D1"
        />
        <path
          d="M291.721 341.947C291.375 341.947 291.068 341.701 291 341.349L286.969 320.161C286.894 319.763 287.155 319.378 287.553 319.302L290.59 318.724C290.781 318.688 290.979 318.728 291.14 318.838C291.301 318.948 291.412 319.117 291.449 319.308L295.48 340.497C295.517 340.688 295.476 340.886 295.366 341.047C295.256 341.208 295.088 341.319 294.896 341.355L291.859 341.933C291.812 341.943 291.766 341.947 291.721 341.947ZM288.549 320.608L292.306 340.354L293.9 340.05L290.143 320.304L288.549 320.608Z"
          fill="#D1D1D1"
        />
        <path
          d="M291.721 341.947C291.375 341.947 291.068 341.701 291 341.349L286.969 320.161C286.894 319.763 287.155 319.378 287.553 319.302L290.59 318.724C290.781 318.688 290.979 318.728 291.14 318.838C291.301 318.948 291.412 319.117 291.449 319.308L295.48 340.497C295.517 340.688 295.476 340.886 295.366 341.047C295.256 341.208 295.088 341.319 294.896 341.355L291.859 341.933C291.812 341.943 291.766 341.947 291.721 341.947ZM288.549 320.608L292.306 340.354L293.9 340.05L290.143 320.304L288.549 320.608Z"
          fill="#D1D1D1"
        />
        <path
          d="M280.558 342.358H239.064C238.53 342.358 238.097 341.925 238.097 341.391V327.466C238.097 326.931 238.53 326.498 239.064 326.498H280.558C281.092 326.498 281.525 326.931 281.525 327.466V341.391C281.525 341.925 281.092 342.358 280.558 342.358ZM240.032 340.423H279.591V328.433H240.032V340.423Z"
          fill="#D1D1D1"
        />
        <path
          d="M280.637 342.358H239.144C238.61 342.358 238.177 341.925 238.177 341.391V327.466C238.177 326.931 238.61 326.498 239.144 326.498H280.637C281.172 326.498 281.605 326.931 281.605 327.466V341.391C281.605 341.925 281.172 342.358 280.637 342.358ZM240.112 340.423H279.67V328.433H240.112V340.423Z"
          fill="#D1D1D1"
        />
        <path
          d="M280.431 332.761H239.144C238.61 332.761 238.177 332.328 238.177 331.793C238.177 331.259 238.61 330.826 239.144 330.826H280.43C280.964 330.826 281.398 331.259 281.398 331.793C281.398 332.328 280.965 332.761 280.431 332.761Z"
          fill="#D1D1D1"
        />
        <path
          d="M263.514 335.644H255.704C254.393 335.644 253.326 334.577 253.326 333.265C253.326 331.953 254.393 330.886 255.704 330.886H263.514C264.825 330.886 265.892 331.953 265.892 333.265C265.892 334.577 264.825 335.644 263.514 335.644ZM255.704 332.821C255.463 332.821 255.26 333.025 255.26 333.265C255.26 333.506 255.463 333.71 255.704 333.71H263.514C263.754 333.71 263.958 333.506 263.958 333.265C263.958 333.025 263.754 332.821 263.514 332.821H255.704Z"
          fill="#D1D1D1"
        />
        <path
          d="M263.514 335.584H255.704C254.393 335.584 253.326 334.517 253.326 333.205C253.326 331.893 254.393 330.826 255.704 330.826H263.514C264.825 330.826 265.892 331.893 265.892 333.205C265.892 334.517 264.825 335.584 263.514 335.584ZM255.704 332.761C255.463 332.761 255.26 332.964 255.26 333.205C255.26 333.446 255.463 333.65 255.704 333.65H263.514C263.754 333.65 263.958 333.446 263.958 333.205C263.958 332.964 263.754 332.761 263.514 332.761H255.704Z"
          fill="#D1D1D1"
        />
        <path
          d="M276.164 328.406H241.267C240.733 328.406 240.299 327.973 240.299 327.439V313.514C240.299 312.98 240.732 312.546 241.267 312.546H276.164C276.698 312.546 277.132 312.979 277.132 313.514V327.439C277.132 327.973 276.699 328.406 276.164 328.406ZM242.234 326.472H275.197V314.481H242.234V326.472Z"
          fill="#D1D1D1"
        />
        <path
          d="M276.232 328.406H241.334C240.8 328.406 240.367 327.973 240.367 327.439V313.514C240.367 312.98 240.8 312.546 241.334 312.546H276.232C276.766 312.546 277.199 312.979 277.199 313.514V327.439C277.199 327.973 276.766 328.406 276.232 328.406ZM242.302 326.472H275.265V314.481H242.302V326.472Z"
          fill="#D1D1D1"
        />
        <path
          d="M276.058 318.809H241.334C240.8 318.809 240.367 318.376 240.367 317.842C240.367 317.307 240.8 316.874 241.334 316.874H276.058C276.592 316.874 277.025 317.307 277.025 317.842C277.025 318.376 276.592 318.809 276.058 318.809Z"
          fill="#D1D1D1"
        />
        <path
          d="M261.723 321.692H255.369C254.122 321.692 253.107 320.678 253.107 319.431V319.197C253.107 317.95 254.122 316.935 255.369 316.935H261.723C262.97 316.935 263.984 317.95 263.984 319.197V319.431C263.984 320.678 262.97 321.692 261.723 321.692ZM255.369 318.869C255.192 318.869 255.042 319.019 255.042 319.197V319.431C255.042 319.608 255.192 319.758 255.369 319.758H261.723C261.9 319.758 262.049 319.608 262.049 319.431V319.197C262.049 319.019 261.9 318.869 261.723 318.869H255.369Z"
          fill="#D1D1D1"
        />
        <path
          d="M261.723 321.632H255.369C254.122 321.632 253.107 320.617 253.107 319.37V319.136C253.107 317.889 254.122 316.875 255.369 316.875H261.723C262.97 316.875 263.984 317.889 263.984 319.136V319.37C263.984 320.617 262.97 321.632 261.723 321.632ZM255.369 318.809C255.192 318.809 255.042 318.959 255.042 319.136V319.37C255.042 319.548 255.192 319.697 255.369 319.697H261.723C261.9 319.697 262.049 319.548 262.049 319.37V319.136C262.049 318.959 261.9 318.809 261.723 318.809H255.369Z"
          fill="#D1D1D1"
        />
        <path
          d="M409.504 349.548H318.652C318.654 349.481 318.665 349.416 318.665 349.348C318.665 345.276 314.412 341.975 309.165 341.975H143.238C137.992 341.975 133.739 345.276 133.739 349.348C133.739 349.416 133.75 349.481 133.752 349.548H39.7337C35.6056 349.548 32.2593 352.146 32.2593 355.35C32.2593 358.554 35.6056 361.151 39.7337 361.151H89.6049C89.2731 362.005 89.0906 362.91 89.0906 363.848C89.0906 369.006 94.4779 373.187 101.124 373.187H353.497C360.143 373.187 365.53 369.006 365.53 363.848C365.53 362.91 365.348 362.005 365.016 361.151H409.504C413.632 361.151 416.979 358.554 416.979 355.35C416.979 352.146 413.632 349.548 409.504 349.548Z"
          fill="#909090"
        />
        <g opacity="0.48">
          <path
            d="M168.059 381.466H88.0048C87.2627 381.466 86.6558 380.859 86.6558 380.117C86.6558 379.375 87.2631 378.768 88.0048 378.768H168.059C168.801 378.768 169.408 379.375 169.408 380.117C169.408 380.859 168.801 381.466 168.059 381.466Z"
            fill="#424243"
          />
        </g>
        <g opacity="0.48">
          <path
            d="M175.615 381.466H173.636C172.893 381.466 172.286 380.859 172.286 380.117C172.286 379.375 172.893 378.768 173.636 378.768H175.615C176.357 378.768 176.964 379.375 176.964 380.117C176.964 380.859 176.357 381.466 175.615 381.466Z"
            fill="#424243"
          />
        </g>
        <g opacity="0.48">
          <path
            d="M242.806 388.662H164.911C164.12 388.662 163.472 388.014 163.472 387.223C163.472 386.431 164.12 385.783 164.911 385.783H242.806C243.598 385.783 244.246 386.431 244.246 387.223C244.245 388.014 243.598 388.662 242.806 388.662Z"
            fill="#424243"
          />
        </g>
        <g opacity="0.48">
          <path
            d="M251.621 388.662H248.023C247.231 388.662 246.583 388.014 246.583 387.223C246.583 386.431 247.231 385.783 248.023 385.783H251.621C252.412 385.783 253.06 386.431 253.06 387.223C253.06 388.014 252.413 388.662 251.621 388.662Z"
            fill="#424243"
          />
        </g>
        <g opacity="0.48">
          <path
            d="M359.654 382.374H281.758C280.967 382.374 280.319 381.727 280.319 380.935C280.319 380.144 280.967 379.496 281.758 379.496H359.654C360.445 379.496 361.093 380.144 361.093 380.935C361.093 381.727 360.445 382.374 359.654 382.374Z"
            fill="#424243"
          />
        </g>
        <g opacity="0.48">
          <path
            d="M368.468 382.374H364.87C364.079 382.374 363.431 381.727 363.431 380.935C363.431 380.144 364.079 379.496 364.87 379.496H368.468C369.26 379.496 369.908 380.144 369.908 380.935C369.908 381.727 369.26 382.374 368.468 382.374Z"
            fill="#424243"
          />
        </g>
        <path
          d="M108.285 159.591H65.5588C64.7415 159.591 64.0791 158.928 64.0791 158.111V104.842C64.0791 104.025 64.7419 103.362 65.5588 103.362H108.285C109.102 103.362 109.765 104.025 109.765 104.842V158.111C109.765 158.928 109.102 159.591 108.285 159.591ZM67.0386 156.631H106.805V106.322H67.0386V156.631Z"
          fill="#D1D1D1"
        />
        <path
          d="M102.418 154.07H71.1804C70.3631 154.07 69.7007 153.407 69.7007 152.59V110.453C69.7007 109.635 70.3635 108.973 71.1804 108.973H102.418C103.236 108.973 103.898 109.636 103.898 110.453V152.59C103.898 153.407 103.235 154.07 102.418 154.07ZM72.6602 151.11H100.938V111.932H72.6602V151.11Z"
          fill="#D1D1D1"
        />
        <path
          d="M164.763 159.591H122.037C121.22 159.591 120.557 158.928 120.557 158.111V104.842C120.557 104.025 121.22 103.362 122.037 103.362H164.763C165.58 103.362 166.243 104.025 166.243 104.842V158.111C166.243 158.928 165.58 159.591 164.763 159.591ZM123.517 156.631H163.283V106.322H123.517V156.631Z"
          fill="#D1D1D1"
        />
        <path
          d="M158.895 154.07H127.658C126.841 154.07 126.178 153.407 126.178 152.59V110.453C126.178 109.635 126.841 108.973 127.658 108.973H158.895C159.713 108.973 160.375 109.636 160.375 110.453V152.59C160.375 153.407 159.713 154.07 158.895 154.07ZM129.138 151.11H157.416V111.932H129.138V151.11Z"
          fill="#D1D1D1"
        />
        <path
          d="M220.498 296.532C248.864 296.532 254.848 285.043 254.37 283.564C253.438 280.676 249.881 251.484 244.342 239.882C238.802 228.278 234.409 228.051 230.398 228.051C226.387 228.051 221.969 228.051 220.441 228.051C218.913 228.051 214.52 228.051 210.509 228.051C206.498 228.051 202.104 228.278 196.565 239.882C191.026 251.484 187.469 280.676 186.537 283.564C186.059 285.043 192.133 296.532 220.498 296.532Z"
          fill="#F9F8F3"
        />
        <path
          d="M256.391 283.266C256.196 282.66 255.715 279.76 255.206 276.69C253.438 266.019 250.478 248.159 246.259 239.32C240.509 227.278 235.476 226.281 230.398 226.281H210.509C205.431 226.281 200.398 227.278 194.649 239.32C190.429 248.158 187.47 266.017 185.701 276.687C185.192 279.758 184.712 282.66 184.516 283.266C184.035 284.756 185.006 286.541 185.905 287.776C188.364 291.154 196.658 299.01 220.499 299.01C244.271 299.01 252.538 291.179 254.99 287.812C255.897 286.566 256.876 284.766 256.391 283.266ZM246.081 289.76C241.903 292.042 234.074 294.763 220.498 294.763H220.497C206.922 294.763 199.072 292.042 194.876 289.76C190.551 287.408 188.981 284.935 188.677 284.144C188.94 283.094 189.284 281.044 189.891 277.381C191.509 267.618 194.521 249.446 198.482 241.15C203.552 230.528 207.089 230.528 210.509 230.528H230.398C233.818 230.528 237.355 230.528 242.426 241.15C246.387 249.446 249.399 267.62 251.017 277.384C251.625 281.056 251.969 283.106 252.233 284.153C251.939 284.956 250.433 287.382 246.081 289.76Z"
          fill="#444445"
        />
        <path
          d="M250.082 287.636C249.907 287.636 249.729 287.59 249.569 287.492C248.491 286.836 248.147 284.753 247.405 279.686C247.056 277.299 246.66 274.594 246.095 271.736C245.868 270.583 245.623 269.229 245.34 267.662C243.824 259.254 241.285 245.18 236.49 238.03C236.449 237.973 236.387 237.883 236.319 237.785C235.457 236.532 233.852 234.201 230.078 234.201H210.828C207.053 234.201 205.449 236.533 204.586 237.785C204.519 237.883 204.457 237.973 204.401 238.052C199.622 245.179 197.083 259.251 195.566 267.658C195.283 269.227 195.039 270.582 194.81 271.737C194.246 274.595 193.85 277.3 193.501 279.686C192.759 284.753 192.414 286.836 191.337 287.492C190.872 287.777 190.264 287.629 189.98 287.163C189.708 286.718 189.832 286.143 190.25 285.844C190.687 285.267 191.143 282.152 191.546 279.4C191.898 276.991 192.298 274.26 192.873 271.353C193.098 270.215 193.34 268.868 193.622 267.307C195.266 258.194 197.751 244.422 202.776 236.929C202.842 236.835 202.898 236.754 202.959 236.665C203.874 235.336 206.015 232.225 210.828 232.225H230.078C234.891 232.225 237.032 235.336 237.946 236.665C238.007 236.754 238.064 236.835 238.115 236.907C243.156 244.424 245.641 258.197 247.284 267.312C247.565 268.871 247.809 270.217 248.033 271.354C248.608 274.26 249.007 276.991 249.36 279.401C249.763 282.153 250.219 285.267 250.656 285.845C251.074 286.143 251.197 286.718 250.926 287.163C250.741 287.467 250.415 287.636 250.082 287.636Z"
          fill="#444445"
        />
        <path
          d="M189.324 364.505C188.53 364.332 188.021 363.54 188.194 362.746L195.07 329.549L202.564 296.719C202.737 295.925 203.529 295.416 204.324 295.589C205.119 295.762 205.627 296.554 205.454 297.349L198.627 330.323L191.083 363.374C190.911 364.169 190.119 364.678 189.324 364.505Z"
          fill="#F9F8F3"
        />
        <path
          d="M189.995 365.601C189.815 365.601 189.633 365.581 189.452 365.542C188.79 365.398 188.224 365.004 187.857 364.432C187.489 363.86 187.366 363.181 187.511 362.519L194.384 329.333L201.883 296.483C202.025 295.832 202.419 295.265 202.991 294.897C203.563 294.53 204.243 294.407 204.904 294.551C205.565 294.695 206.132 295.089 206.499 295.661C206.867 296.233 206.99 296.912 206.846 297.574L200.02 330.538L192.473 363.61C192.331 364.261 191.937 364.828 191.365 365.196C190.949 365.463 190.477 365.601 189.995 365.601ZM204.365 296.617C204.262 296.617 204.182 296.657 204.139 296.684C204.084 296.719 203.987 296.8 203.956 296.946L196.459 329.785L189.588 362.961C189.554 363.116 189.608 363.23 189.644 363.285C189.679 363.339 189.76 363.436 189.904 363.467L189.905 363.467C190.048 363.497 190.163 363.444 190.218 363.41C190.273 363.374 190.369 363.293 190.401 363.148L197.946 330.086L204.769 297.133C204.819 296.902 204.674 296.674 204.453 296.627C204.422 296.62 204.393 296.617 204.365 296.617Z"
          fill="#444445"
        />
        <path
          d="M251.559 364.505C252.354 364.332 252.862 363.54 252.69 362.746L245.814 329.549L238.319 296.719C238.146 295.925 237.354 295.416 236.559 295.589C235.765 295.762 235.256 296.554 235.429 297.349L242.257 330.323L249.8 363.374C249.973 364.169 250.764 364.678 251.559 364.505Z"
          fill="#F9F8F3"
        />
        <path
          d="M250.888 365.601C250.406 365.601 249.934 365.463 249.518 365.196C248.946 364.828 248.552 364.261 248.407 363.6L240.867 330.559L234.035 297.563C233.893 296.913 234.016 296.233 234.383 295.661C234.751 295.089 235.318 294.695 235.98 294.551C236.642 294.408 237.32 294.531 237.892 294.898C238.464 295.265 238.858 295.832 239.002 296.494L246.494 329.312L253.375 362.53C253.517 363.181 253.394 363.86 253.026 364.432C252.659 365.004 252.092 365.398 251.431 365.542C251.25 365.581 251.068 365.601 250.888 365.601ZM236.518 296.617C236.491 296.617 236.461 296.62 236.431 296.626C236.286 296.657 236.205 296.754 236.17 296.809C236.134 296.864 236.08 296.977 236.112 297.122L242.942 330.107L250.48 363.138C250.514 363.293 250.611 363.374 250.666 363.409C250.72 363.444 250.835 363.497 250.979 363.467L250.979 363.467C251.123 363.435 251.204 363.339 251.24 363.284C251.275 363.229 251.329 363.116 251.298 362.971L244.419 329.764L236.929 296.956C236.895 296.8 236.799 296.719 236.744 296.684C236.701 296.656 236.621 296.617 236.518 296.617Z"
          fill="#444445"
        />
        <path
          d="M240.633 325.421C240.404 325.421 240.174 325.347 239.98 325.196L205.17 298.008C204.708 297.646 204.626 296.98 204.987 296.517C205.348 296.055 206.015 295.973 206.478 296.334L241.287 323.522C241.75 323.883 241.832 324.55 241.471 325.012C241.261 325.281 240.948 325.421 240.633 325.421Z"
          fill="#444445"
        />
        <path
          d="M200.25 325.421C199.935 325.421 199.622 325.28 199.413 325.012C199.052 324.55 199.133 323.883 199.596 323.522L234.405 296.334C234.868 295.973 235.535 296.055 235.896 296.517C236.257 296.98 236.175 297.646 235.713 298.008L200.903 325.196C200.709 325.347 200.479 325.421 200.25 325.421Z"
          fill="#444445"
        />
        <path
          d="M224.016 282.332H211.981C210.504 282.332 209.306 281.134 209.306 279.657V257.763C209.306 256.285 210.504 255.088 211.981 255.088H224.016C225.494 255.088 226.691 256.285 226.691 257.763V279.657C226.691 281.134 225.494 282.332 224.016 282.332Z"
          fill="#A4A6A8"
        />
        <path
          d="M224.016 283.073H211.981C210.098 283.073 208.565 281.54 208.565 279.657V257.763C208.565 255.879 210.098 254.346 211.981 254.346H224.016C225.9 254.346 227.433 255.879 227.433 257.763V279.657C227.433 281.54 225.9 283.073 224.016 283.073ZM211.981 255.829C210.915 255.829 210.048 256.697 210.048 257.762V279.656C210.048 280.722 210.915 281.589 211.981 281.589H224.016C225.082 281.589 225.949 280.722 225.949 279.656V257.762C225.949 256.697 225.082 255.829 224.016 255.829H211.981Z"
          fill="#424243"
        />
        <path
          d="M221.621 266.033C219.26 266.033 218.041 264.269 217.956 262.44C215.969 262.463 214.365 264.079 214.365 266.072C214.365 268.079 215.992 269.705 217.999 269.705C220.006 269.705 221.633 268.078 221.633 266.072C221.633 266.058 221.631 266.046 221.631 266.033C221.627 266.032 221.624 266.033 221.621 266.033Z"
          fill="#59595B"
        />
        <path
          d="M240.442 295.569C226.256 291.027 212.363 290.674 198.838 295.569C193.306 295.569 188.821 289.556 188.821 282.14C188.821 274.723 193.306 268.71 198.838 268.71H240.442C245.974 268.71 250.459 274.723 250.459 282.14C250.459 289.556 245.974 295.569 240.442 295.569Z"
          fill="black"
        />
        <path
          d="M240.442 296.558H240.288L240.141 296.511C225.706 291.889 211.924 291.885 199.175 296.499L199.012 296.558H198.839C192.769 296.558 187.832 290.09 187.832 282.139C187.832 274.189 192.769 267.72 198.838 267.72H240.442C246.511 267.72 251.448 274.189 251.448 282.139C251.449 290.09 246.511 296.558 240.442 296.558ZM219.007 291.062C226.011 291.062 233.219 292.234 240.592 294.578C245.501 294.468 249.47 288.931 249.47 282.139C249.47 275.279 245.42 269.699 240.443 269.699H198.838C193.86 269.699 189.81 275.279 189.81 282.139C189.81 288.923 193.77 294.455 198.671 294.578C205.209 292.234 212.004 291.062 219.007 291.062Z"
          fill="#444445"
        />
        <path
          d="M224.443 283.101H214.885C199.965 283.101 187.758 270.894 187.758 255.974V239.598C187.758 224.678 199.965 212.47 214.885 212.47H224.443C239.363 212.47 251.57 224.677 251.57 239.598V255.974C251.57 270.894 239.363 283.101 224.443 283.101Z"
          fill="#B6872C"
        />
        <path
          d="M224.443 283.843H214.884C199.517 283.843 187.016 271.341 187.016 255.974V239.598C187.016 224.231 199.517 211.729 214.884 211.729H224.443C239.81 211.729 252.312 224.23 252.312 239.598V255.974C252.312 271.341 239.81 283.843 224.443 283.843ZM214.884 213.213C200.335 213.213 188.499 225.049 188.499 239.598V255.974C188.499 270.523 200.335 282.36 214.884 282.36H224.443C238.992 282.36 250.828 270.523 250.828 255.974V239.598C250.828 225.049 238.992 213.213 224.443 213.213H214.884Z"
          fill="#444445"
        />
        <path
          d="M251.57 244.387H187.758V251.034H251.57V244.387Z"
          fill="#CECFD1"
        />
        <path
          d="M251.57 252.177H187.757C187.126 252.177 186.614 251.665 186.614 251.034V244.387C186.614 243.756 187.126 243.244 187.757 243.244H251.57C252.201 243.244 252.713 243.756 252.713 244.387V251.034C252.714 251.665 252.201 252.177 251.57 252.177ZM188.901 249.89H250.426V245.531H188.901V249.89Z"
          fill="#444445"
        />
        <path
          d="M237.462 219.046C237.441 226.735 229.618 232.948 219.989 232.922L219.653 232.921C210.023 232.895 202.234 226.641 202.254 218.952L202.329 190.843C202.35 183.154 210.173 176.942 219.802 176.968L220.138 176.968C229.768 176.994 237.557 183.248 237.537 190.937L237.462 219.046Z"
          fill="#D4AC97"
        />
        <path
          d="M220.139 176.968H220.131V232.919C229.695 232.883 237.441 226.697 237.462 219.046L237.537 190.937C237.557 183.248 229.768 176.994 220.139 176.968Z"
          fill="#DBB79D"
        />
        <path
          d="M220.049 233.251C220.028 233.251 220.009 233.251 219.989 233.251L219.652 233.25C214.921 233.238 210.473 231.752 207.129 229.067C203.763 226.365 201.915 222.773 201.926 218.952L202.001 190.842C202.022 183.001 209.976 176.639 219.744 176.639C219.764 176.639 219.783 176.639 219.803 176.639L220.139 176.64C224.871 176.652 229.318 178.138 232.662 180.823C236.028 183.525 237.876 187.117 237.865 190.938L237.791 219.047C237.77 226.889 229.816 233.251 220.049 233.251ZM219.743 177.296C210.337 177.296 202.678 183.364 202.659 190.844L202.583 218.953C202.574 222.57 204.334 225.979 207.541 228.554C210.769 231.146 215.07 232.58 219.654 232.592L219.99 232.593C220.009 232.593 220.03 232.593 220.049 232.593C229.455 232.593 237.113 226.526 237.133 219.045L237.208 190.936C237.218 187.319 235.457 183.91 232.251 181.335C229.023 178.743 224.721 177.309 220.138 177.297L219.802 177.296C219.782 177.296 219.762 177.296 219.743 177.296Z"
          fill="#444445"
        />
        <path
          d="M220.049 233.536L219.651 233.535C214.855 233.522 210.344 232.014 206.95 229.29C203.515 226.532 201.629 222.86 201.64 218.95L201.715 190.841C201.736 182.852 209.823 176.352 219.743 176.352L220.14 176.353C224.937 176.366 229.447 177.874 232.841 180.599C236.276 183.357 238.162 187.028 238.151 190.938L238.077 219.047C238.055 227.037 229.968 233.536 220.049 233.536ZM219.743 176.638V176.924C210.138 176.924 202.307 183.168 202.286 190.842L202.211 218.952C202.201 222.684 204.011 226.197 207.308 228.844C210.602 231.488 214.986 232.951 219.653 232.964L220.049 232.965C229.654 232.965 237.485 226.721 237.506 219.046L237.581 190.937C237.59 187.204 235.781 183.692 232.484 181.045C229.19 178.401 224.806 176.938 220.139 176.925L219.803 176.924L219.743 176.638ZM220.049 232.878L219.653 232.877C215.005 232.865 210.64 231.409 207.362 228.776C204.086 226.146 202.288 222.658 202.297 218.952L202.372 190.842C202.393 183.215 210.185 177.01 219.743 177.01L220.138 177.011C224.785 177.023 229.15 178.48 232.429 181.112C235.705 183.742 237.503 187.231 237.493 190.936L237.419 218.76L237.462 218.76L237.46 219.332H237.414C237.203 226.828 229.487 232.878 220.049 232.878ZM219.801 177.582H219.743C210.5 177.582 202.964 183.531 202.944 190.844L202.869 218.954C202.86 222.482 204.582 225.812 207.719 228.331C210.897 230.882 215.135 232.294 219.654 232.306L220.049 232.307C229.292 232.307 236.828 226.358 236.847 219.045L236.922 190.935C236.931 187.407 235.209 184.077 232.071 181.558C228.893 179.006 224.655 177.595 220.137 177.583L219.801 177.582Z"
          fill="#444445"
        />
        <path
          d="M235.897 211.476V191.035L220.146 190.548C220.148 191.427 220.15 199.249 220.152 200.128C219.884 200.18 219.627 200.276 219.396 200.41C218.869 200.715 218.485 201.234 218.427 201.799C218.369 202.365 218.661 202.961 219.191 203.26C219.722 203.56 220.473 203.52 220.93 203.133V211.476H235.897V211.476Z"
          fill="#DBB79D"
        />
        <path
          d="M212.409 199.604C212.407 200.259 211.875 200.788 211.221 200.786C210.566 200.785 210.037 200.252 210.039 199.598C210.041 198.943 210.573 198.414 211.227 198.416C211.882 198.417 212.411 198.949 212.409 199.604Z"
          fill="#444445"
        />
        <path
          d="M229.965 199.604C229.963 200.259 229.431 200.788 228.777 200.786C228.122 200.785 227.593 200.252 227.595 199.598C227.596 198.943 228.128 198.414 228.783 198.416C229.438 198.417 229.967 198.949 229.965 199.604Z"
          fill="#444445"
        />
        <path
          d="M227.251 196.159C227.267 196.144 227.283 196.13 227.299 196.116C227.314 196.103 227.329 196.091 227.344 196.078C227.439 196 227.251 196.146 227.373 196.058C227.545 195.934 227.733 195.829 227.932 195.757C228.596 195.515 229.248 195.718 229.779 196.159C230.273 196.57 230.988 195.863 230.489 195.448C229.716 194.806 228.809 194.485 227.813 194.741C227.354 194.86 226.884 195.119 226.54 195.448C226.071 195.898 226.783 196.608 227.251 196.159Z"
          fill="#444445"
        />
        <path
          d="M209.952 196.159C209.968 196.144 209.984 196.13 210 196.116C210.015 196.103 210.03 196.091 210.045 196.078C210.141 196 209.952 196.146 210.074 196.058C210.246 195.934 210.434 195.829 210.633 195.757C211.298 195.515 211.949 195.718 212.48 196.159C212.975 196.57 213.69 195.863 213.19 195.448C212.417 194.806 211.51 194.485 210.515 194.741C210.055 194.86 209.585 195.119 209.241 195.448C208.773 195.898 209.484 196.608 209.952 196.159Z"
          fill="#444445"
        />
        <path
          d="M229.175 202.343C227.507 202.343 226.155 203.232 226.155 204.328C226.155 205.425 227.507 206.314 229.175 206.314C230.842 206.314 232.194 205.425 232.194 204.328C232.194 203.232 230.842 202.343 229.175 202.343Z"
          fill="#AE7D77"
        />
        <path
          d="M211.156 206.314C212.824 206.314 214.176 205.425 214.176 204.328C214.176 203.231 212.824 202.343 211.156 202.343C209.488 202.343 208.136 203.231 208.136 204.328C208.136 205.425 209.488 206.314 211.156 206.314Z"
          fill="#AE7D77"
        />
        <path
          d="M219.97 203.739C219.651 203.739 219.329 203.663 219.052 203.507C218.438 203.161 218.073 202.463 218.145 201.771C218.211 201.129 218.625 200.529 219.253 200.164C219.446 200.053 219.652 199.966 219.868 199.904L219.862 198.09C219.862 197.933 219.988 197.806 220.145 197.806H220.145C220.302 197.806 220.428 197.932 220.429 198.088L220.436 200.362L220.205 200.406C219.967 200.452 219.743 200.535 219.538 200.655C219.073 200.924 218.755 201.374 218.709 201.828C218.66 202.299 218.91 202.775 219.331 203.013C219.768 203.261 220.391 203.218 220.746 202.917C220.866 202.815 221.045 202.83 221.146 202.949C221.247 203.069 221.232 203.248 221.113 203.349C220.809 203.606 220.392 203.739 219.97 203.739Z"
          fill="#444445"
        />
        <path
          d="M195.408 275.735L194.633 275.909C192.617 276.363 190.614 275.095 190.161 273.078L183.186 242.047C182.733 240.03 184 238.028 186.017 237.574L186.792 237.4C188.809 236.947 190.811 238.214 191.264 240.231L198.239 271.263C198.692 273.28 197.425 275.282 195.408 275.735Z"
          fill="#DBB79D"
        />
        <path
          d="M193.803 276.991C192.918 276.991 192.048 276.741 191.282 276.256C190.213 275.58 189.473 274.529 189.196 273.296L182.221 242.265C181.649 239.719 183.255 237.182 185.8 236.61L186.575 236.436C187.808 236.158 189.076 236.378 190.143 237.054C191.211 237.73 191.952 238.782 192.229 240.014L199.204 271.046C199.481 272.279 199.261 273.546 198.585 274.614C197.909 275.682 196.858 276.423 195.625 276.7L194.85 276.874C194.503 276.952 194.152 276.991 193.803 276.991ZM187.612 238.298C187.413 238.298 187.211 238.32 187.008 238.366L186.233 238.54C184.752 238.873 183.818 240.349 184.151 241.83L191.126 272.862C191.458 274.344 192.937 275.28 194.417 274.944L195.191 274.77C195.909 274.609 196.521 274.178 196.915 273.556C197.308 272.935 197.435 272.197 197.274 271.48L190.3 240.448C190.012 239.169 188.872 238.298 187.612 238.298Z"
          fill="#424243"
        />
        <path
          d="M194.584 223.571C189.184 221.475 184.949 225.259 183.287 229.825C182.452 232.118 181.997 234.593 181.997 237.174V255.559L194.446 255.557L194.584 223.571Z"
          fill="#B6872C"
        />
        <path
          d="M193.94 239.789L193.875 254.983L182.571 254.985V237.174C182.571 234.717 182.993 232.31 183.826 230.021C185.218 226.194 188.498 223.991 192.612 223.991C193.068 223.991 193.534 224.018 194.008 224.073L194.002 226.753C194.001 227.071 194.258 227.329 194.576 227.329C194.892 227.329 195.148 227.074 195.15 226.758L195.158 223.574C195.159 223.294 194.956 223.048 194.679 223.006C189.121 222.146 184.546 224.686 182.747 229.629C181.868 232.044 181.423 234.583 181.423 237.174V255.559C181.423 255.711 181.484 255.857 181.591 255.965C181.699 256.072 181.845 256.133 181.997 256.133L194.446 256.131C194.762 256.131 195.018 255.875 195.019 255.56L195.088 239.795C195.089 239.477 194.832 239.219 194.514 239.219C194.198 239.218 193.942 239.473 193.94 239.789Z"
          fill="#444445"
        />
        <path
          d="M181.997 256.418C181.768 256.418 181.552 256.329 181.389 256.167C181.227 256.004 181.137 255.788 181.137 255.558V237.173C181.137 234.549 181.588 231.978 182.478 229.531C184.328 224.449 189.018 221.84 194.723 222.722C195.136 222.787 195.446 223.153 195.444 223.574L195.435 226.758C195.433 227.23 195.047 227.614 194.576 227.614C194.346 227.614 194.129 227.524 193.967 227.361C193.804 227.198 193.715 226.981 193.716 226.751L193.722 224.329C189.206 223.9 185.546 226.128 184.094 230.118C183.273 232.375 182.856 234.748 182.856 237.173V254.699L193.59 254.697L193.655 239.788C193.656 239.316 194.042 238.932 194.514 238.932C194.744 238.932 194.961 239.022 195.123 239.185C195.286 239.348 195.375 239.565 195.373 239.795L195.305 255.56C195.303 256.032 194.917 256.416 194.446 256.416L181.997 256.418ZM192.589 223.127C188.114 223.127 184.54 225.538 183.016 229.726C182.149 232.111 181.709 234.616 181.709 237.173V255.558C181.709 255.635 181.739 255.707 181.794 255.762C181.848 255.817 181.921 255.846 181.997 255.846L194.446 255.844C194.605 255.844 194.734 255.716 194.735 255.558L194.803 239.793C194.803 239.716 194.773 239.643 194.719 239.588C194.665 239.534 194.592 239.503 194.515 239.503C194.357 239.503 194.228 239.632 194.227 239.79L194.16 255.269L182.286 255.271V237.173C182.286 234.682 182.714 232.243 183.557 229.923C184.974 226.029 188.36 223.704 192.613 223.704C193.078 223.704 193.559 223.732 194.041 223.788L194.295 223.818L194.288 226.753C194.288 226.831 194.317 226.903 194.372 226.958C194.427 227.013 194.499 227.043 194.576 227.043C194.734 227.043 194.864 226.914 194.864 226.756L194.873 223.572C194.873 223.431 194.771 223.309 194.636 223.288C193.936 223.18 193.252 223.127 192.589 223.127Z"
          fill="#444445"
        />
        <path
          d="M198.117 281.336C193.171 281.336 189.162 286.506 189.162 292.117V347.104H207.072L207.346 294.314C207.346 288.703 210.81 280.664 198.117 281.336Z"
          fill="black"
        />
        <path
          d="M208.12 348.158H188.108V292.117C188.108 285.714 192.676 280.302 198.089 280.283C202.433 280.054 205.377 280.811 207.073 282.588C209.251 284.87 208.907 288.3 208.605 291.328C208.499 292.389 208.399 293.391 208.399 294.315L208.12 348.158ZM190.215 346.051H206.024L206.292 294.309C206.292 293.286 206.402 292.184 206.509 291.118C206.779 288.417 207.058 285.624 205.549 284.043C204.316 282.751 201.834 282.194 198.173 282.388L198.117 282.39C193.908 282.39 190.215 286.935 190.215 292.117V346.051Z"
          fill="#444445"
        />
        <path
          d="M242.402 281.663C247.328 282.173 250.907 287.734 250.458 293.322L246.056 348.082L228.219 346.237L232.173 293.637C232.622 288.049 229.816 279.686 242.402 281.663Z"
          fill="black"
        />
        <path
          d="M247.02 349.241L227.092 347.179L231.122 293.558C231.196 292.632 231.177 291.624 231.156 290.556C231.098 287.509 231.031 284.054 233.389 281.998C235.227 280.395 238.221 279.944 242.539 280.619C247.914 281.194 252.019 287.042 251.507 293.407L247.02 349.241ZM229.346 345.295L245.092 346.924L249.407 293.237C249.823 288.065 246.499 283.146 242.294 282.711L242.238 282.704C238.61 282.134 236.097 282.43 234.773 283.586C233.156 284.996 233.21 287.802 233.263 290.516C233.283 291.588 233.305 292.697 233.222 293.722L229.346 345.295Z"
          fill="#444445"
        />
        <path
          d="M209.815 357.918H187.29C186.729 357.918 186.275 357.464 186.275 356.903V355.666C186.275 352.391 188.93 349.736 192.205 349.736H204.901C208.175 349.736 210.831 352.391 210.831 355.666V356.903C210.83 357.464 210.375 357.918 209.815 357.918Z"
          fill="#B9BABD"
        />
        <path
          d="M209.814 358.977H187.29C186.146 358.977 185.215 358.047 185.215 356.903V355.666C185.215 351.812 188.35 348.677 192.204 348.677H204.9C208.754 348.677 211.889 351.812 211.889 355.666V356.903C211.889 358.047 210.958 358.977 209.814 358.977ZM187.333 356.86H209.771V355.666C209.771 352.98 207.586 350.796 204.9 350.796H192.204C189.518 350.796 187.333 352.98 187.333 355.666V356.86Z"
          fill="#454446"
        />
        <path
          d="M247.899 358.461H225.452C224.87 358.461 224.398 357.926 224.398 357.265C224.398 353.407 227.155 349.038 230.556 349.038L242.796 350.28C246.196 350.28 248.954 353.407 248.954 357.265C248.954 357.926 248.481 358.461 247.899 358.461Z"
          fill="#B9BABD"
        />
        <path
          d="M247.899 359.52H225.452C224.287 359.52 223.339 358.509 223.339 357.265C223.339 351.95 227.149 347.979 230.556 347.979C230.591 347.979 230.627 347.981 230.662 347.985L242.852 349.221C246.805 349.255 250.012 352.851 250.012 357.266C250.013 358.509 249.065 359.52 247.899 359.52ZM225.495 357.402H247.857C247.873 357.38 247.895 357.335 247.895 357.265C247.895 353.997 245.608 351.338 242.796 351.338C242.761 351.338 242.725 351.337 242.689 351.333L230.507 350.097C227.782 350.143 225.457 354.057 225.457 357.265C225.457 357.335 225.479 357.381 225.495 357.402Z"
          fill="#454446"
        />
        <path
          d="M242.184 188.026C242.146 187.701 242.095 187.382 242.025 187.069C241.505 184.726 240.601 182.452 239.116 180.548C237.167 178.05 234.4 176.487 231.701 175.17C228.274 173.501 223.882 172.905 222.152 172.725C216.658 172.163 210.472 173.324 205.181 175.919C202.317 177.326 199.874 179.349 198.122 181.764C196.462 184.05 195.79 186.616 195.14 189.097C194.635 191.021 194.158 192.84 193.258 194.483C192.989 194.699 192.497 195.11 192.494 195.806C192.488 196.579 193.109 197.071 193.342 197.256C194.056 197.821 195 198.213 196.07 198.388C198.751 198.826 201.347 197.974 203.436 197.288C207.026 196.11 210.161 196.679 215.218 193.284C219.365 190.5 219.664 182.554 228.189 187.443C231.857 186.475 233.131 187.227 233.398 191.994C233.482 193.496 235.968 195.553 237.294 196.271C237.858 196.577 238.455 196.796 239.031 197.075C239.559 197.33 240.411 198.117 241.028 197.956C241.404 197.858 241.563 197.416 241.633 197.033C241.739 196.447 241.779 195.851 241.817 195.256C241.966 192.975 242.45 190.358 242.184 188.026Z"
          fill="#444445"
        />
        <path
          d="M204.823 276.058C204.655 279.719 201.669 282.555 198.155 282.393C195.687 282.279 193.521 280.647 192.594 278.379C191.371 275.387 192.669 272.574 194.22 269.994C194.953 268.774 195.776 267.476 197.098 266.947C197.187 267.751 197.215 268.563 197.18 269.371C199.321 269.072 201.134 269.301 202.771 270.863C204.173 272.201 204.912 274.129 204.823 276.058Z"
          fill="#DBB79D"
        />
        <path
          d="M198.445 283.389C198.334 283.389 198.221 283.386 198.11 283.381C195.3 283.252 192.775 281.435 191.679 278.753C190.299 275.378 191.677 272.304 193.372 269.485C194.078 268.311 195.044 266.703 196.73 266.029C197.017 265.916 197.341 265.941 197.606 266.099C197.87 266.258 198.046 266.531 198.08 266.838C198.134 267.315 198.166 267.797 198.178 268.28C199.825 268.198 201.722 268.495 203.454 270.148C205.056 271.675 205.915 273.847 205.811 276.103C205.718 278.13 204.87 280.003 203.425 281.376C202.051 282.681 200.294 283.389 198.445 283.389ZM196.204 268.837C195.806 269.303 195.442 269.88 195.067 270.504C193.415 273.252 192.518 275.58 193.51 278.005C194.315 279.977 196.157 281.311 198.2 281.405C199.628 281.471 201 280.952 202.063 279.942C203.136 278.922 203.765 277.527 203.835 276.013C203.912 274.353 203.259 272.696 202.089 271.579C200.858 270.405 199.475 270.049 197.316 270.351C197.026 270.392 196.732 270.301 196.515 270.103C196.297 269.906 196.179 269.622 196.192 269.329C196.199 269.165 196.203 269.001 196.204 268.837Z"
          fill="#424243"
        />
        <path
          d="M244.854 275.735L245.629 275.909C247.645 276.363 249.648 275.095 250.102 273.078L257.076 242.047C257.529 240.03 256.262 238.028 254.245 237.574L253.471 237.4C251.454 236.947 249.451 238.214 248.998 240.231L242.023 271.263C241.57 273.28 242.837 275.282 244.854 275.735Z"
          fill="#DBB79D"
        />
        <path
          d="M246.459 276.991C246.11 276.991 245.76 276.953 245.412 276.875L244.637 276.7C243.404 276.423 242.353 275.682 241.677 274.614C241.001 273.546 240.781 272.28 241.059 271.046L248.033 240.015C248.605 237.47 251.141 235.862 253.688 236.436L254.462 236.61C257.008 237.182 258.613 239.719 258.041 242.265L251.067 273.296C250.79 274.529 250.049 275.581 248.981 276.256C248.214 276.741 247.345 276.991 246.459 276.991ZM252.649 238.298C251.39 238.298 250.25 239.169 249.963 240.449L242.988 271.48C242.827 272.197 242.954 272.935 243.348 273.557C243.742 274.178 244.353 274.609 245.071 274.77L245.846 274.945C247.331 275.281 248.804 274.344 249.137 272.862L256.111 241.831C256.444 240.349 255.51 238.873 254.029 238.54L253.254 238.366C253.051 238.32 252.849 238.298 252.649 238.298Z"
          fill="#424243"
        />
        <path
          d="M244.942 223.571C250.342 222.74 254.577 225.259 256.24 229.825C257.074 232.118 257.529 234.593 257.529 237.174V255.559L245.08 255.557L244.942 223.571Z"
          fill="#B6872C"
        />
        <path
          d="M245.586 239.789L245.652 254.983L256.956 254.984V237.173C256.956 234.716 256.534 232.31 255.701 230.021C254.308 226.194 251.028 223.99 246.914 223.99C246.459 223.99 245.992 224.017 245.518 224.072L245.524 225.487C245.525 225.805 245.268 226.064 244.95 226.064C244.634 226.064 244.378 225.808 244.377 225.492L244.368 223.573C244.367 223.293 244.57 223.048 244.847 223.005C250.405 222.146 254.98 224.685 256.779 229.629C257.658 232.044 258.103 234.582 258.103 237.174V255.559C258.103 255.711 258.042 255.857 257.935 255.964C257.827 256.072 257.681 256.132 257.529 256.132L245.08 256.13C244.764 256.13 244.508 255.875 244.506 255.559L244.438 239.794C244.437 239.476 244.694 239.218 245.012 239.218C245.328 239.217 245.585 239.473 245.586 239.789Z"
          fill="#444445"
        />
        <path
          d="M257.529 256.418L245.08 256.416C244.608 256.416 244.223 256.032 244.221 255.561L244.152 239.795C244.151 239.565 244.241 239.348 244.403 239.185C244.565 239.022 244.782 238.932 245.012 238.932C245.484 238.932 245.87 239.315 245.871 239.788L245.936 254.697L256.669 254.698V237.173C256.669 234.749 256.253 232.375 255.431 230.118C253.979 226.129 250.323 223.901 245.804 224.329L245.809 225.485C245.811 225.716 245.721 225.932 245.559 226.096C245.396 226.259 245.18 226.349 244.95 226.349C244.478 226.349 244.092 225.965 244.09 225.493L244.082 223.574C244.08 223.153 244.389 222.786 244.802 222.722C250.503 221.842 255.197 224.449 257.047 229.531C257.937 231.978 258.388 234.549 258.388 237.173V255.558C258.388 255.788 258.299 256.004 258.137 256.166C257.977 256.326 257.755 256.418 257.529 256.418ZM245.012 239.503C244.935 239.503 244.862 239.534 244.808 239.588C244.753 239.643 244.724 239.715 244.724 239.792L244.792 255.558C244.793 255.716 244.922 255.844 245.08 255.844L257.529 255.846C257.605 255.846 257.679 255.816 257.733 255.762C257.788 255.707 257.818 255.635 257.818 255.558V237.173C257.818 234.616 257.378 232.11 256.51 229.726C254.759 224.917 250.307 222.447 244.89 223.287C244.755 223.308 244.653 223.431 244.653 223.572L244.662 225.491C244.663 225.649 244.792 225.778 244.95 225.778C245.027 225.778 245.1 225.748 245.154 225.693C245.209 225.638 245.238 225.566 245.238 225.488L245.231 223.818L245.485 223.788C245.967 223.732 246.448 223.704 246.914 223.704C251.167 223.704 254.552 226.029 255.969 229.923C256.813 232.242 257.241 234.682 257.241 237.173V255.271L245.367 255.269L245.3 239.79C245.299 239.632 245.17 239.503 245.012 239.503Z"
          fill="#444445"
        />
        <path
          d="M217.344 210.328C217.396 210.328 217.449 210.336 217.501 210.354C220.159 211.278 222.232 210.378 222.252 210.37C222.493 210.26 222.776 210.37 222.884 210.611C222.992 210.852 222.884 211.135 222.643 211.242C222.543 211.288 220.202 212.303 217.187 211.258C216.937 211.171 216.806 210.899 216.891 210.649C216.961 210.452 217.146 210.328 217.344 210.328Z"
          fill="#424243"
        />
        <path
          d="M233.493 284.639L224.811 265.387C224.233 264.104 224.809 262.581 226.092 262.003L234.917 258.023C236.199 257.445 237.722 258.021 238.301 259.304L246.983 278.557C247.561 279.839 246.985 281.362 245.702 281.941L236.877 285.92C235.595 286.499 234.071 285.922 233.493 284.639Z"
          fill="#A4A6A8"
        />
        <path
          d="M235.831 287.135C234.478 287.135 233.183 286.357 232.592 285.046L223.911 265.793C223.522 264.932 223.493 263.969 223.829 263.08C224.165 262.192 224.825 261.489 225.686 261.101L234.51 257.122C236.294 256.317 238.399 257.114 239.203 258.897L247.884 278.15C248.688 279.933 247.892 282.038 246.108 282.842L237.284 286.822C236.812 287.034 236.318 287.135 235.831 287.135ZM234.395 284.233C234.566 284.612 234.877 284.904 235.271 285.053C235.665 285.201 236.091 285.19 236.471 285.019L245.295 281.039C246.085 280.683 246.437 279.752 246.081 278.962L237.4 259.71C237.044 258.921 236.112 258.569 235.324 258.924L226.499 262.904C226.119 263.075 225.828 263.386 225.679 263.78C225.53 264.174 225.542 264.6 225.713 264.98L234.395 284.233Z"
          fill="#424243"
        />
        <path
          d="M235.439 276.058C235.608 279.719 238.593 282.555 242.107 282.393C244.575 282.279 246.741 280.647 247.668 278.379C248.891 275.387 247.593 272.574 246.042 269.994C245.309 268.774 244.486 267.476 243.164 266.947C243.075 267.751 243.048 268.563 243.082 269.371C240.941 269.072 239.128 269.301 237.491 270.863C236.089 272.201 235.35 274.129 235.439 276.058Z"
          fill="#DBB79D"
        />
        <path
          d="M241.817 283.389C239.968 283.389 238.212 282.681 236.837 281.376C235.392 280.003 234.544 278.13 234.451 276.103C234.347 273.847 235.206 271.676 236.808 270.148C238.54 268.495 240.438 268.199 242.084 268.28C242.096 267.797 242.128 267.316 242.181 266.838C242.215 266.531 242.391 266.257 242.656 266.099C242.921 265.94 243.244 265.914 243.531 266.029C245.217 266.703 246.184 268.311 246.89 269.485C248.584 272.304 249.963 275.378 248.583 278.753C247.487 281.435 244.962 283.251 242.152 283.38C242.041 283.386 241.928 283.389 241.817 283.389ZM236.427 276.013C236.497 277.527 237.126 278.922 238.2 279.942C239.262 280.952 240.628 281.472 242.062 281.405C244.106 281.311 245.947 279.977 246.753 278.005C247.744 275.58 246.847 273.252 245.195 270.504C244.82 269.881 244.457 269.304 244.058 268.837C244.06 269.001 244.064 269.165 244.071 269.329C244.083 269.622 243.965 269.906 243.748 270.103C243.531 270.301 243.236 270.393 242.947 270.351C240.786 270.048 239.404 270.406 238.173 271.579C237.003 272.696 236.35 274.353 236.427 276.013Z"
          fill="#424243"
        />
        <path
          d="M237.537 210.677H240.599C242.005 210.677 243.156 209.526 243.156 208.12V196.565C243.156 195.159 242.005 194.008 240.599 194.008H237.537V210.677Z"
          fill="#E6AB4A"
        />
        <path
          d="M240.598 211.172H237.537C237.264 211.172 237.042 210.95 237.042 210.677V194.008C237.042 193.735 237.264 193.514 237.537 193.514H240.598C242.281 193.514 243.65 194.882 243.65 196.565V208.12C243.65 209.803 242.281 211.172 240.598 211.172ZM238.031 210.183H240.598C241.736 210.183 242.661 209.257 242.661 208.12V196.564C242.661 195.427 241.736 194.502 240.598 194.502H238.031V210.183Z"
          fill="#424243"
        />
        <path
          d="M202.254 210.677H199.193C197.787 210.677 196.636 209.526 196.636 208.12V196.565C196.636 195.159 197.786 194.008 199.193 194.008H202.254V210.677Z"
          fill="#E6AB4A"
        />
        <path
          d="M202.254 211.172H199.193C197.51 211.172 196.142 209.803 196.142 208.12V196.565C196.142 194.882 197.51 193.514 199.193 193.514H202.254C202.528 193.514 202.749 193.735 202.749 194.008V210.677C202.749 210.95 202.528 211.172 202.254 211.172ZM199.193 194.502C198.056 194.502 197.13 195.427 197.13 196.565V208.12C197.13 209.258 198.055 210.183 199.193 210.183H201.76V194.503H199.193V194.502Z"
          fill="#424243"
        />
        <path
          d="M237.537 194.008C237.537 194.008 238.647 176.967 219.802 176.967C200.957 176.967 202.254 194.008 202.254 194.008H199.445C199.445 194.008 198.236 173.317 219.664 173.317C241.092 173.317 240.346 194.008 240.346 194.008H237.537Z"
          fill="#DE902F"
        />
        <path
          d="M240.346 194.502H237.537C237.4 194.502 237.27 194.446 237.176 194.346C237.083 194.246 237.034 194.113 237.043 193.976C237.047 193.907 237.429 187.04 232.915 182.229C229.946 179.066 225.534 177.461 219.802 177.461C214.073 177.461 209.681 179.064 206.748 182.223C202.285 187.032 202.743 193.902 202.747 193.971C202.758 194.108 202.71 194.243 202.617 194.344C202.523 194.445 202.392 194.502 202.254 194.502H199.445C199.183 194.502 198.966 194.298 198.951 194.037C198.929 193.661 198.481 184.761 204.34 178.549C207.924 174.75 213.08 172.823 219.664 172.823C226.249 172.823 231.448 174.751 235.114 178.552C241.102 184.76 240.853 193.65 240.839 194.026C240.83 194.292 240.612 194.502 240.346 194.502ZM238.046 193.514H239.851C239.812 191.6 239.329 184.339 234.397 179.232C230.924 175.635 225.967 173.812 219.664 173.812C213.367 173.812 208.455 175.632 205.066 179.221C200.237 184.335 199.924 191.616 199.929 193.513H201.74C201.71 191.736 201.952 185.944 206.016 181.558C209.144 178.183 213.782 176.472 219.802 176.472C225.823 176.472 230.48 178.184 233.643 181.56C237.755 185.949 238.056 191.743 238.046 193.514Z"
          fill="#424243"
        />
        <path
          d="M219.664 248.527C212.95 248.527 207.681 246.287 204.004 241.87C195.049 231.114 198.562 211.378 198.716 210.543L200.175 210.811C200.138 211.009 196.636 230.705 205.146 240.923C208.528 244.985 213.413 247.044 219.664 247.044C225.928 247.044 230.846 244.977 234.283 240.9C242.909 230.664 239.649 211 239.615 210.803L241.077 210.552C241.221 211.386 244.483 231.098 235.417 241.855C231.686 246.282 226.386 248.527 219.664 248.527Z"
          fill="#444445"
        />
        <path
          d="M228.208 300.67C226.797 300.67 225.28 300.519 223.641 300.217C216.641 298.931 212.8 294.938 212.825 288.975C212.845 284.2 214.768 279.617 216.626 275.184C217.164 273.902 217.672 272.691 218.135 271.475C219.992 266.606 221.784 260.799 220.994 254.644C220.916 254.039 220.886 253.398 220.855 252.719C220.776 251.029 220.695 249.281 219.859 248.254L221.009 247.317C222.154 248.724 222.251 250.81 222.336 252.651C222.367 253.298 222.395 253.91 222.465 254.455C223.296 260.936 221.444 266.962 219.522 272.003C219.049 273.242 218.513 274.521 217.994 275.758C216.191 280.056 214.327 284.501 214.309 288.982C214.281 295.599 219.514 297.95 223.909 298.758C227.495 299.418 230.423 299.316 232.863 298.448C237.641 296.746 239.556 292.59 239.069 284.97L240.549 284.875C240.881 290.085 240.499 297.304 233.36 299.845C231.815 300.395 230.108 300.67 228.208 300.67Z"
          fill="#444445"
        />
        <path
          d="M266.429 252.081H189.02L188.368 251.86V200.617C188.368 198.161 190.065 196.151 192.139 196.151H262.847C264.921 196.151 266.618 198.161 266.618 200.617V251.7L266.429 252.081Z"
          fill="#F4F5F5"
        />
        <path
          d="M262.488 254.426H192.499C189.216 254.426 186.545 251.755 186.545 248.473V200.054C186.545 196.772 189.216 194.101 192.499 194.101H262.488C265.77 194.101 268.441 196.772 268.441 200.054V248.473C268.441 251.756 265.77 254.426 262.488 254.426ZM192.499 197.747C191.226 197.747 190.191 198.782 190.191 200.054V248.473C190.191 249.745 191.227 250.78 192.499 250.78H262.488C263.76 250.78 264.795 249.745 264.795 248.473V200.054C264.795 198.782 263.76 197.747 262.488 197.747H192.499Z"
          fill="#444445"
        />
        <path
          d="M239.311 263.733H215.905L218.314 233.181H236.902L239.311 263.733Z"
          fill="#F4F5F5"
        />
        <path
          d="M239.311 264.946H215.905C215.566 264.946 215.243 264.805 215.013 264.556C214.783 264.308 214.668 263.974 214.695 263.637L217.104 233.085C217.154 232.454 217.681 231.967 218.314 231.967H236.901C237.535 231.967 238.061 232.454 238.111 233.085L240.521 263.637C240.547 263.975 240.432 264.308 240.202 264.556C239.973 264.805 239.649 264.946 239.311 264.946ZM217.218 262.518H237.998L235.78 234.395H219.436L217.218 262.518Z"
          fill="#444445"
        />
        <path
          d="M364.758 264.435H81.7632V269.002H364.758V264.435Z"
          fill="#BEAD94"
        />
        <path
          d="M364.758 270.254H81.7633C81.0721 270.254 80.5117 269.693 80.5117 269.002V264.435C80.5117 263.744 81.0721 263.184 81.7633 263.184H364.758C365.449 263.184 366.01 263.744 366.01 264.435V269.002C366.01 269.693 365.449 270.254 364.758 270.254ZM83.0144 267.751H363.507V265.686H83.0144V267.751Z"
          fill="#444445"
        />
        <path
          d="M348.351 358.003L344.468 358.015L322.677 268.863L326.499 268.779L348.351 358.003Z"
          fill="#2D2E2A"
        />
        <path
          d="M344.37 359.491C343.794 359.491 343.292 359.097 343.155 358.537L321.365 269.384C321.274 269.015 321.356 268.626 321.587 268.325C321.818 268.024 322.173 267.844 322.553 267.836L326.375 267.751C326.95 267.752 327.479 268.134 327.618 268.705L349.469 357.929C349.561 358.302 349.476 358.696 349.239 358.998C349.003 359.3 348.641 359.477 348.258 359.479L344.375 359.492C344.373 359.491 344.372 359.491 344.37 359.491ZM324.165 270.304L345.352 356.985L346.66 356.981L325.425 270.276L324.165 270.304Z"
          fill="#444445"
        />
        <path
          d="M297.224 358.003L301.107 358.015L322.897 268.863L319.075 268.779L297.224 358.003Z"
          fill="#2D2E2A"
        />
        <path
          d="M301.009 359.491C301.008 359.491 301.007 359.491 301.005 359.491L297.122 359.478C296.739 359.477 296.377 359.3 296.141 358.997C295.905 358.695 295.82 358.301 295.911 357.929L317.762 268.704C317.902 268.134 318.427 267.752 319.006 267.751L322.827 267.835C323.207 267.844 323.562 268.024 323.792 268.324C324.023 268.626 324.105 269.015 324.015 269.383L302.225 358.536C302.088 359.097 301.586 359.491 301.009 359.491ZM298.72 356.98L300.028 356.985L321.214 270.303L319.954 270.275L298.72 356.98Z"
          fill="#444445"
        />
        <path
          d="M145.678 358.227L141.795 358.24L120.004 269.086L123.826 269.002L145.678 358.227Z"
          fill="#2D2E2A"
        />
        <path
          d="M94.5508 358.227L98.4337 358.24L120.224 269.086L116.402 269.002L94.5508 358.227Z"
          fill="#2D2E2A"
        />
        <path
          d="M141.843 359.491C141.267 359.491 140.765 359.097 140.628 358.537L118.837 269.384C118.747 269.016 118.829 268.626 119.06 268.325C119.291 268.025 119.646 267.845 120.025 267.836L123.847 267.751C124.421 267.752 124.95 268.135 125.09 268.705L146.941 357.93C147.032 358.302 146.948 358.696 146.712 358.998C146.476 359.3 146.114 359.477 145.73 359.479L141.847 359.492C141.846 359.491 141.845 359.491 141.843 359.491ZM121.638 270.303L142.825 356.985L144.133 356.981L122.898 270.276L121.638 270.303Z"
          fill="#444445"
        />
        <path
          d="M98.4825 359.491C98.4813 359.491 98.4797 359.491 98.4781 359.491L94.5952 359.478C94.212 359.477 93.8499 359.3 93.614 358.997C93.3777 358.695 93.2927 358.301 93.3841 357.929L115.235 268.704C115.375 268.134 115.898 267.752 116.478 267.751L120.3 267.835C120.68 267.844 121.035 268.024 121.265 268.324C121.497 268.626 121.578 269.015 121.488 269.383L99.6977 358.536C99.5609 359.097 99.0591 359.491 98.4825 359.491ZM96.1931 356.98L97.5009 356.985L118.688 270.303L117.427 270.275L96.1931 356.98Z"
          fill="#444445"
        />
        <path
          d="M136.43 338.583H103.248C102.541 338.583 101.969 338.011 101.969 337.304C101.969 336.598 102.541 336.025 103.248 336.025H136.43C137.137 336.025 137.709 336.598 137.709 337.304C137.709 338.011 137.137 338.583 136.43 338.583Z"
          fill="#444445"
        />
        <path
          d="M339.31 338.583H306.128C305.421 338.583 304.849 338.011 304.849 337.304C304.849 336.598 305.421 336.025 306.128 336.025H339.31C340.017 336.025 340.589 336.598 340.589 337.304C340.589 338.011 340.017 338.583 339.31 338.583Z"
          fill="#444445"
        />
        <path
          d="M146.138 230.633H125.537V262.431H146.138V230.633Z"
          fill="#BEAD94"
        />
        <path
          d="M124.932 263.167V239.926H88.7156V263.167H124.932Z"
          fill="#F9F8F3"
        />
        <path
          d="M146.525 264.079H125.339C124.597 264.079 123.996 263.477 123.996 262.735V230.133C123.996 229.391 124.597 228.789 125.339 228.789H146.525C147.267 228.789 147.868 229.391 147.868 230.133V262.735C147.869 263.477 147.267 264.079 146.525 264.079ZM126.683 261.392H145.182V231.476H126.683V261.392Z"
          fill="#444445"
        />
        <path
          d="M143.915 260.662H128.04C127.698 260.662 127.42 260.384 127.42 260.042V232.741C127.42 232.399 127.698 232.122 128.04 232.122H143.915C144.257 232.122 144.534 232.399 144.534 232.741V260.042C144.534 260.384 144.257 260.662 143.915 260.662ZM128.659 259.423H143.295V233.361H128.659V259.423Z"
          fill="#444445"
        />
        <path
          d="M125.025 264.737H88.5177C87.7756 264.737 87.1743 264.136 87.1743 263.394V239.671C87.1743 238.929 87.7756 238.328 88.5177 238.328H125.025C125.767 238.328 126.368 238.929 126.368 239.671V263.394C126.368 264.135 125.767 264.737 125.025 264.737ZM89.8615 262.05H123.682V241.014H89.8615V262.05Z"
          fill="#444445"
        />
        <path
          d="M122.349 261.334H91.2641C90.9129 261.334 90.6284 261.05 90.6284 260.698V242.303C90.6284 241.952 90.9129 241.668 91.2641 241.668H122.349C122.7 241.668 122.985 241.952 122.985 242.303V260.698C122.985 261.049 122.7 261.334 122.349 261.334ZM91.8998 260.062H121.713V242.939H91.8998V260.062Z"
          fill="#444445"
        />
        <path
          d="M271.324 255.93V259.44H295.816V255.93H271.324Z"
          fill="#C1D38B"
        />
        <path
          d="M295.816 259.93H271.325C271.054 259.93 270.834 259.711 270.834 259.44V255.93C270.834 255.659 271.054 255.44 271.325 255.44H295.816C296.087 255.44 296.306 255.659 296.306 255.93V259.44C296.306 259.711 296.087 259.93 295.816 259.93ZM271.814 258.95H295.326V256.42H271.814V258.95Z"
          fill="#444445"
        />
        <path
          d="M272.759 259.594V262.899H295.816V259.594H272.759Z"
          fill="#B76C65"
        />
        <path
          d="M295.816 263.297H272.759C272.488 263.297 272.269 263.078 272.269 262.807V259.502C272.269 259.232 272.488 259.012 272.759 259.012H295.816C296.087 259.012 296.306 259.232 296.306 259.502V262.807C296.306 263.078 296.087 263.297 295.816 263.297ZM273.249 262.317H295.326V259.992H273.249V262.317Z"
          fill="#444445"
        />
        <path
          d="M290.974 247.94L289.068 250.377L306.066 263.673L307.971 261.237L290.974 247.94Z"
          fill="#F9F8F3"
        />
        <path
          d="M306.087 264.162C305.981 264.162 305.875 264.128 305.785 264.058L288.786 250.761C288.684 250.681 288.618 250.563 288.602 250.434C288.586 250.305 288.622 250.175 288.703 250.073L290.609 247.636C290.775 247.424 291.083 247.385 291.296 247.553L308.295 260.85C308.397 260.93 308.464 261.047 308.479 261.177C308.495 261.305 308.459 261.436 308.379 261.538L306.473 263.974C306.376 264.097 306.232 264.162 306.087 264.162ZM289.776 250.291L306.003 262.985L307.306 261.32L291.079 248.626L289.776 250.291Z"
          fill="#444445"
        />
        <path
          d="M95.984 310.359C92.8048 304.632 89.3929 294.058 80.7301 294.237C71.1172 294.435 69.6427 304.239 66.2446 310.36C64.236 313.977 62.1752 317.812 62.4483 321.851C62.8088 327.175 68.791 330.884 74.3514 330.884H81.1141H87.8768C93.4376 330.884 99.4198 327.175 99.7799 321.851C100.054 317.812 97.993 313.977 95.984 310.359Z"
          fill="#BAD387"
        />
        <path
          d="M97.7999 309.297C94.0579 302.454 90.7314 292.646 81.5093 292.15C77.134 291.914 73.1702 293.886 70.5263 297.315C68.0217 300.563 66.8247 304.497 65.0334 308.135C62.6611 312.952 59.1788 318.093 60.6395 323.748C61.8688 328.507 66.5727 331.732 71.1968 332.649C74.1944 333.243 77.3724 332.987 80.4116 332.987C82.7455 332.987 85.0798 333.001 87.4134 332.987C92.1909 332.957 97.1161 331.11 99.9797 327.122C104.046 321.462 100.753 314.635 97.7999 309.297ZM96.5091 324.783C94.4289 327.614 90.7828 328.758 87.413 328.781C83.2157 328.809 79.0127 328.809 74.8159 328.781C71.4469 328.758 67.7995 327.613 65.7193 324.783C62.6748 320.641 66.0167 315.212 68.0605 311.421C70.867 306.216 72.4099 297.574 79.3425 296.454C87.799 295.089 91.0061 305.639 94.1675 311.42C96.2494 315.182 99.535 320.666 96.5091 324.783Z"
          fill="#424243"
        />
        <path
          d="M99.0807 337.319H63.1479V361.395H99.0807V337.319Z"
          fill="#EEEFEF"
        />
        <path
          d="M99.0805 359.292C88.5988 359.292 78.1172 359.292 67.6351 359.292C66.1392 359.292 64.6437 359.292 63.1477 359.292C63.849 359.993 64.5498 360.694 65.251 361.395C65.251 353.37 65.251 345.344 65.251 337.319C64.5498 338.02 63.849 338.721 63.1477 339.422C73.6294 339.422 84.111 339.422 94.5931 339.422C96.089 339.422 97.5846 339.422 99.0805 339.422C98.3793 338.721 97.6784 338.02 96.9772 337.319C96.9772 345.344 96.9772 353.37 96.9772 361.395C96.9772 364.102 101.184 364.106 101.184 361.395C101.184 353.37 101.184 345.344 101.184 337.319C101.184 336.182 100.22 335.216 99.0805 335.216C88.5988 335.216 78.1172 335.216 67.6351 335.216C66.1392 335.216 64.6437 335.216 63.1477 335.216C62.0107 335.216 61.0444 336.179 61.0444 337.319C61.0444 345.344 61.0444 353.37 61.0444 361.395C61.0444 362.532 62.0079 363.499 63.1477 363.499C73.6294 363.499 84.111 363.499 94.5931 363.499C96.089 363.499 97.5846 363.499 99.0805 363.499C101.787 363.499 101.792 359.292 99.0805 359.292Z"
          fill="#424243"
        />
        <path
          d="M78.9819 322.568C78.9819 327.387 78.9819 332.207 78.9819 337.026C78.9819 339.733 83.1885 339.738 83.1885 337.026C83.1885 332.207 83.1885 327.387 83.1885 322.568C83.1885 319.861 78.9819 319.857 78.9819 322.568Z"
          fill="#424243"
        />
        <path
          d="M368.437 340.325C367.958 340.325 367.57 339.722 367.57 338.977V289.609C367.57 288.864 367.958 288.261 368.437 288.261C368.916 288.261 369.304 288.864 369.304 289.609V338.977C369.304 339.722 368.916 340.325 368.437 340.325Z"
          fill="#424243"
        />
        <path
          d="M369.486 316.45C369.486 316.45 382.488 316.641 383.618 303.693C383.618 303.693 371.398 304.576 369.486 316.45Z"
          fill="#BAD387"
        />
        <path
          d="M369.484 317.704C369.475 317.704 369.47 317.704 369.467 317.704C369.104 317.7 368.76 317.535 368.526 317.257C368.291 316.977 368.19 316.611 368.248 316.251C370.295 303.543 383.394 302.451 383.527 302.443C383.909 302.416 384.253 302.552 384.51 302.813C384.768 303.075 384.898 303.436 384.866 303.802C383.669 317.533 370.044 317.704 369.484 317.704ZM382.152 305.184C379.202 305.804 372.959 307.943 371.054 315.09C374.112 314.708 380.697 312.978 382.152 305.184Z"
          fill="#424243"
        />
        <path
          d="M357.538 314.12C357.538 314.12 357.129 325.026 367.972 326.221C367.972 326.221 367.465 315.951 357.538 314.12Z"
          fill="#BAD387"
        />
        <path
          d="M367.972 327.273C367.934 327.273 367.895 327.271 367.857 327.267C356.176 325.979 356.481 314.198 356.486 314.08C356.498 313.774 356.642 313.489 356.88 313.298C357.118 313.107 357.428 313.032 357.729 313.084C368.352 315.044 369.018 326.058 369.024 326.169C369.039 326.477 368.918 326.776 368.694 326.987C368.497 327.171 368.238 327.273 367.972 327.273ZM358.65 315.462C358.912 318.035 360.238 323.593 366.748 324.963C366.285 322.475 364.609 317.196 358.65 315.462Z"
          fill="#424243"
        />
        <path
          d="M355.879 294.525C355.879 294.525 355.411 307.006 367.821 308.375C367.821 308.375 367.24 296.62 355.879 294.525Z"
          fill="#BAD387"
        />
        <path
          d="M367.82 309.579C367.777 309.579 367.732 309.577 367.689 309.573C354.32 308.098 354.669 294.615 354.675 294.48C354.688 294.129 354.853 293.804 355.125 293.585C355.398 293.366 355.753 293.279 356.097 293.34C368.255 295.583 369.017 308.19 369.023 308.317C369.041 308.669 368.902 309.011 368.646 309.253C368.421 309.463 368.125 309.579 367.82 309.579ZM357.151 296.06C357.451 299.006 358.968 305.366 366.42 306.934C365.889 304.088 363.972 298.045 357.151 296.06Z"
          fill="#424243"
        />
        <path
          d="M368.428 296.45C368.428 296.45 374.823 288.434 367.294 281.48C367.294 281.48 361.947 289.527 368.428 296.45Z"
          fill="#BAD387"
        />
        <path
          d="M368.428 297.438C368.155 297.438 367.893 297.326 367.705 297.126C360.77 289.717 366.413 281.02 366.47 280.933C366.631 280.692 366.888 280.533 367.176 280.498C367.467 280.46 367.752 280.557 367.965 280.754C376.075 288.245 369.271 296.978 369.2 297.066C369.021 297.29 368.754 297.425 368.466 297.437C368.454 297.438 368.44 297.438 368.428 297.438ZM367.517 283.114C366.479 285.253 364.799 290.179 368.336 294.815C369.574 292.724 371.674 287.783 367.517 283.114Z"
          fill="#424243"
        />
        <path
          d="M368.625 299.237C368.625 299.237 378.825 298.182 378.509 287.937C378.509 287.937 369.022 289.761 368.625 299.237Z"
          fill="#BAD387"
        />
        <path
          d="M368.625 300.225C368.375 300.225 368.132 300.13 367.947 299.957C367.739 299.76 367.625 299.482 367.637 299.195C368.061 289.056 378.219 286.986 378.322 286.966C378.608 286.913 378.901 286.984 379.127 287.166C379.353 287.346 379.488 287.618 379.497 287.906C379.837 298.943 368.838 300.209 368.726 300.22C368.692 300.223 368.659 300.225 368.625 300.225ZM377.499 289.241C375.246 290 370.555 292.253 369.728 298.026C372.087 297.443 377.081 295.478 377.499 289.241Z"
          fill="#424243"
        />
        <path
          d="M378.759 335.553H359.484V355.564H378.759V335.553Z"
          fill="#EEEFEF"
        />
        <path
          d="M378.759 354.774C373.136 354.774 367.514 354.774 361.891 354.774C361.088 354.774 360.286 354.774 359.484 354.774C359.671 355.037 359.857 355.3 360.044 355.564C360.044 348.893 360.044 342.223 360.044 335.553C359.857 335.816 359.671 336.079 359.484 336.342C365.106 336.342 370.729 336.342 376.352 336.342C377.154 336.342 377.956 336.342 378.759 336.342C378.572 336.079 378.385 335.816 378.198 335.553C378.198 342.223 378.198 348.893 378.198 355.564C378.198 356.579 379.319 356.58 379.319 355.564C379.319 348.893 379.319 342.223 379.319 335.553C379.319 335.126 379.062 334.764 378.759 334.764C373.136 334.764 367.513 334.764 361.89 334.764C361.088 334.764 360.286 334.764 359.483 334.764C359.18 334.764 358.922 335.125 358.922 335.553C358.922 342.223 358.922 348.893 358.922 355.564C358.922 355.99 359.179 356.353 359.483 356.353C365.106 356.353 370.729 356.353 376.351 356.353C377.154 356.353 377.956 356.353 378.758 356.353C379.481 356.353 379.482 354.774 378.759 354.774Z"
          fill="#424243"
        />
        <path
          d="M381.116 331.676H357.127V335.591H381.116V331.676Z"
          fill="#EEEFEF"
        />
        <path
          d="M381.116 334.802C374.115 334.802 367.113 334.802 360.112 334.802C359.117 334.802 358.122 334.802 357.127 334.802C357.314 335.065 357.501 335.328 357.688 335.591C357.688 334.286 357.688 332.981 357.688 331.676C357.501 331.939 357.314 332.202 357.127 332.465C364.129 332.465 371.13 332.465 378.131 332.465C379.126 332.465 380.121 332.465 381.116 332.465C380.929 332.202 380.742 331.939 380.556 331.676C380.556 332.981 380.556 334.286 380.556 335.591C380.556 336.606 381.677 336.608 381.677 335.591C381.677 334.286 381.677 332.981 381.677 331.676C381.677 331.25 381.42 330.887 381.116 330.887C374.115 330.887 367.114 330.887 360.113 330.887C359.118 330.887 358.123 330.887 357.128 330.887C356.825 330.887 356.567 331.249 356.567 331.676C356.567 332.981 356.567 334.286 356.567 335.591C356.567 336.017 356.824 336.38 357.128 336.38C364.129 336.38 371.13 336.38 378.131 336.38C379.126 336.38 380.121 336.38 381.116 336.38C381.838 336.38 381.838 334.802 381.116 334.802Z"
          fill="#424243"
        />
      </g>
      <defs>
        <clipPath id="clip0_183_2679">
          <rect width="454" height="454" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
